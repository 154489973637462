<template>
  <div v-if="currentUser.superAdmin">
    <v-data-table
        :headers="headers"
        :items="walletApprovals"
        :options.sync="pagination"
        :server-items-length="totalWalletApprovals"
        :loading="loading"
        :footer-props="{
        'showFirstLastPage':true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
        class="elevation-1"
    >
      <template v-slot:item.status="{ item }">
        <v-chip
            class="ma-2"
            color="green darken-1"
            text-color="white"
            v-if="item.status === 'APPROVED'"
        >
          APPROVED
        </v-chip>
        <v-chip
            class="ma-2"
            color="red darken-1"
            text-color="white"
            v-if="item.status === 'REJECTED'"
        >
          REJECTED
        </v-chip>
        <v-chip
            class="ma-2"
            color="yellow darken-1"
            text-color="black"
            v-if="item.status === 'PENDING_APPROVAL'"
        >
          PENDING APPROVAL
        </v-chip>
        <v-chip
            class="ma-2"
            color="grey accent-1"
            text-color="white"
            v-if="item.status === 'NOT_REQUESTED'"
        >
          NOT_REQUESTED
        </v-chip>
        {{item.status !== 'APPROVED' && item.status !== 'REJECTED' && item.status !== 'PENDING_APPROVAL' && item.status !== 'NOT_REQUESTED' ? item.status : ""}}
      </template>
      <template v-slot:item.approve="{ item }">
        <td>
          <v-btn @click="approveDialog(item)"
                 v-if="item.status !== 'APPROVED'"
                 color="success"
                 fab
                 x-small
                 dark
          >
            <v-icon>mdi-checkbox-marked-circle</v-icon>
          </v-btn>
        </td>
      </template>

      <template v-slot:item.reject="{ item }">
        <td>
          <v-btn @click="rejectDialog(item)"
                 v-if="item.status !== 'REJECTED' && item.status !== 'APPROVED'"
                 color="error"
                 fab
                 x-small
                 dark
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>

        </td>
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-text-field v-model="shopId" type="text"
                          @keyup.enter="page = 1;retrieveWalletApprovals();"></v-text-field>
          </td>
          <td>
            <v-text-field v-model="shopName" type="text"
                          @keyup.enter="page = 1;retrieveWalletApprovals();"></v-text-field>
          </td>
          <td>
            <v-text-field v-model="membershipId" type="text"
                          @keyup.enter="page = 1;retrieveWalletApprovals();"></v-text-field>
          </td>
          <td>
            <v-text-field v-model="walletAmount" type="text"
                          @keyup.enter="page = 1;retrieveWalletApprovals();"></v-text-field>
          </td>
          <td>
            <v-text-field v-model="iban" type="text"
                          @keyup.enter="page = 1;retrieveWalletApprovals();"></v-text-field>
          </td>
          <td>
            <v-text-field v-model="bic" type="text"
                          @keyup.enter="page = 1;retrieveWalletApprovals();"></v-text-field>
          </td>
          <td>
            <v-select
                :items="statusList"
                v-model="status"
                dense
                hide-details
                @keyup.enter="page = 1;retrieveWalletApprovals();"
            ></v-select>
          </td>
          <td>
            <v-avatar color="indigo" size="30">
              <v-icon
                  dark
                  @click="
                  page = 1;
                  retrieveWalletApprovals();
                "
              >mdi-magnify
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <v-avatar color="grey" size="30">
              <v-icon
                  dark
                  @click="
                  page = 1;
                  clearSearch();
                "
              >mdi-close
              </v-icon>
            </v-avatar>
          </td>
        </tr>
      </template>
    </v-data-table>
    <ConfirmDialog ref="confirm"/>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import {mapGetters} from "vuex";
import WalletApiService from "@/core/services/api.service.wallet";
import ConfirmDialog from "@/view/pages/ConfirmDialog";

export default {
  name: "wallet-approval",
  computed: {
    ...mapGetters(["currentUser"]),
    activeSubscriptionsList() {
      return [this.$i18n.t("common.all"), this.$i18n.t("common.no"), this.$i18n.t("common.yes"),]
    },
    headers() {
      return [
        {text: this.$i18n.t('pages.membership.shopId'), value: "shopId", sortable: false, width: "5%"},
        {text: this.$i18n.t('pages.membership.shopName'), value: "shopName", sortable: false},
        {text: this.$i18n.t('pages.membership.membershipId'), value: "membershipId", sortable: false},
        {text: this.$i18n.t('pages.membership.walletAmount'), value: "amount", sortable: false},
        {text: this.$i18n.t('pages.membership.iban'), value: "iban", sortable: false, width: "5%"},
        {text: this.$i18n.t('pages.membership.bic'), value: "bic", sortable: false, width: "5%"},
        {text: this.$i18n.t('pages.membership.status'), value: "status", sortable: false, width: "15%"},
        {text: "", value: "approve", sortable: false, width: "5%"},
        {text: "", value: "reject", sortable: false, width: "5%"},
      ]
    }
  },
  data() {
    return {
      pageSizes: [10, 20, 50, 100],
      totalWalletApprovals: 0,
      walletApprovals: [],
      loading: false,
      walletApiInitiated: false,
      pagination: {},
      loader: null,
      errors: [],
      dialog: false,
      fav: true,
      shopName: null,
      shopId: null,
      membershipId: null,
      walletAmount: null,
      iban: null,
      bic: null,
      status: null,
      statusList: ["ALL", "NOT_REQUESTED", "PENDING_APPROVAL", "APPROVED", "REJECTED"],
      numberRule: v => {
        console.log("v" + v);
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
        return 'Number has to be between 0 and 999';
      },
      voucherRule: [
        v => !!v || "Field is required",
        v => (v && v > 0) || "Voucher should be a positive number",
      ],
      requiredRule: [v => !!v || "Field is required"]
    };
  },
  watch: {
    pagination: {
      handler() {
        if (this.walletApiInitiated) this.retrieveWalletApprovals();
      },
      deep: true
    }
  },
  components: {
    ConfirmDialog
  },
  async created() {
    if (!this.currentUser.superAdmin) return;
    this.loading = true;
    await WalletApiService.init();
    this.walletApiInitiated = true;
    this.retrieveWalletApprovals();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Shop Information"}]);
  },
  methods: {
    async approveDialog(item) {
      if (
          await this.$refs.confirm.open(
              this.$i18n.t('common.confirm'),
              this.$i18n.t('pages.membership.approveMessage') +
              item.amount + " wallet amount for " +
              item.shopName +
              " ?"
          )
      ) {
        this.approve(item);
      }
    },
    approve(item) {
      console.log("Approve item:" + item.shopName)
      this.changeStatus(item, true);
    },
    changeStatus(item, status) {
      if (item.membershipId != null) {
        let changeStatusRequest = {};
        changeStatusRequest['subscriptionId'] = item.membershipId;
        changeStatusRequest['isApproved'] = status;
        changeStatusRequest['reason'] = "";
        WalletApiService.post(`/ChangeVoucherRedeemStatus`, changeStatusRequest)
            .then(response => {
              this.$log.debug("Status changed: " + response.data);
            })
            .catch(error => {
              this.$log.error("Error: ", error);
              this.errored = true;
            })
            .finally(() => {
              this.retrieveWalletApprovals();
            });
      }
    },
    async rejectDialog(item) {
      if (
          await this.$refs.confirm.open(
              this.$i18n.t('common.confirm'),
              this.$i18n.t('pages.membership.rejectMessage') +
              item.amount + " wallet amount for " +
              item.shopName +
              " ?"
          )
      ) {
        this.reject(item);
      }
    },
    reject(item) {
      console.log("Reject item:" + item.shopName)
      this.changeStatus(item, false);
    },
    parseDate(timestamp) {
      return moment
          .utc(timestamp)
          .local()
          .format("yyyy-MM-DD HH:mm:ss");
    },

    getRequestParams(
        shopId,
        shopName,
        membershipId,
        walletAmount,
        iban,
        bic,
        status
    ) {
      let params = {};

      if (shopId) {
        params["shopId"] = shopId;
      }

      if (shopName) {
        params["shopName"] = shopName;
      }

      if (membershipId) {
        params["membershipId"] = membershipId;
      }

      if (walletAmount) {
        params["walletAmount"] = walletAmount;
      }

      if (iban) {
        params["iban"] = iban;
      }

      if (bic) {
        params["bic"] = bic;
      }
      if (status && (status !== this.$i18n.t("common.all"))) {
        params["status"] = status;
      }


      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["sort"] = sort;
      params["page"] = this.pagination.page;
      params["size"] = this.pagination.itemsPerPage;

      return params;
    },
    clearSearch() {
      this.shopId = "";
      this.shopName = "";
      this.membershipId = "";
      this.walletAmount = "";
      this.iban = "";
      this.bic = "";
      this.status = "";
    },
    retrieveWalletApprovals() {
      const params = this.getRequestParams(
          this.shopId,
          this.shopName,
          this.membershipId,
          this.walletAmount,
          this.iban,
          this.bic,
          this.status,
      );
      this.$log.debug("params: ", params);
      return new Promise(resolve => {
        this.loading = true;
        WalletApiService.query("/GetWalletApproval",
            params
        ).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("GetWalletApproval response: ", response.data);
          this.walletApprovals = response.data.content;
          this.totalWalletApprovals = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title = this.totalWalletApprovals;
          this.$store.dispatch(SET_BREADCRUMB, [{title: "Wallet Approvals: " + title}]);
          resolve();
        });
      });
    }
  }
};


</script>
<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}

tbody tr {
  width: 100%;
}

.filter {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  background-color: aliceblue;
  border: 1px solid brown;
}

.search {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.search-item {
  justify-content: center;
  display: flex;
}

</style>
